<template>
  <div class="personality_item">
    <div class="item" v-for="(item, index) of evaluationHotList" :key="index">
      <div class="img_box" @click="handelGoDetail(item.code)">
        <img :src="item.xcxImageUrl" alt="" />
      </div>
      <div class="status_box" @click="handelGoDetail(item.code)">
        <h3>{{ item.name }}</h3>
        <p>{{ strNumber(item.description) }}</p>
        <span>{{ item.finishedNum }}人已测</span>
      </div>
      <!-- <div
        class="status_btn"
        v-if="item.finished == 0"
        @click="handelStart(item.finished, item.code, item.name)"
      >
        开始测评
      </div>
      <div
        class="status_btn report"
        v-if="item.finished == 1"
        @click="handelEvaluationReport(item.finished, item.code, item.name)"
      >
        测评报告
      </div>
      <div
        class="status_btn begin"
        v-if="item.finished == 2"
        @click="handelStart(item.finished, item.code, item.name)"
      >
        继续测评
      </div> -->
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  data() {
    return {};
  },
  components: {},
  methods: {
    // 开始测评
    handelStart(finished, id, name) {
      // this.$emit("handelStart", finished, id);
      this.$router.push({ path: "/notice", query: { finished: finished, evaluationId: id } });
      localStorage.setItem("name", JSON.stringify(name));
    },

    // 测评报告
    handelEvaluationReport(finished, id, name) {
      this.$router.push({ path: "/report", query: { code: id } });
      // this.$router.push({ path: "/EvaluationReport", query: { code: id } });
      localStorage.setItem("name", JSON.stringify(name));
    },

    // 测评详情
    handelGoDetail(id) {
      this.$router.push({ path: "/mbti", query: { id: id } });
    },

    strNumber(data) {
      let length = data.length;
      if(length > 35) {
        let str = '';
        str = data.substring(0, 25) + '...';
        return str;
      } else {
        return data
      }
    }
  },
  props: {
    evaluationHotList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
};
</script>

<style scoped lang="less">
.personality_item {
  background: #ffffff;
  // box-shadow: 0px 0px 0.24rem 0px rgba(148, 148, 148, 0.15);
  border-radius: 0.08rem;
  margin-bottom: 0.4rem;
  // padding: 0.4rem 0rem;
  .title {
    background-image: url("../../assets/images/title_img.png");
    background-repeat: no-repeat;
    background-position: 0.2rem 0.2rem;
    background-size: 1rem 0.12rem;
    font-size: 0.32rem;
    font-family: "PingFangSC-Regular";
    font-weight: 600;
    color: #000000;
    line-height: 0.38rem;
    padding: 0 0.2rem;
    margin: 0.49rem 0 0.4rem 0;
  }
  .item {
    display: flex;
    margin: 0 0 0.5rem 0;
    background: #ffffff;
    padding: 0 0.2rem;
    position: relative;

    .img_box {
      margin: 0 0.2rem 0 0;
      width: 1.8rem;
      height: 1.8rem;
      img {
        width: 1.8rem;
        height: 1.8rem;
        border-radius: 0.08rem;
      }
    }
    .status_box {
      h3 {
        font-size: 0.3rem;
        font-family: "PingFangSC-Regular";
        font-weight: 500;
        color: #333333;
        margin-bottom: 0.2rem;
      }

      p {
        font-size: 0.28rem;
        font-family: "PingFangSC-Regular";
        font-weight: 400;
        color: #666666;
        line-height: 0.34rem;
        margin-bottom: 0.32rem;
      }

      span {
        font-size: 0.26rem;
        font-family: "PingFangSC-Regular";
        font-weight: 400;
        color: #999999;
        line-height: 0.38rem;
      }
    }

    .status_btn {
      font-size: 0.26rem;
      font-family: "PingFangSC-Regular";
      font-weight: 400;
      color: #ffffff;
      padding: 0.18rem 0.28rem;
      background: #0067b0;
      border-radius: 0.5rem;
      position: absolute;
      right: 0.2rem;
      bottom: 0rem;
    }

    .report {
      background: #f2961c;
    }

    .begin {
      background: #00a6e3;
    }
  }

  .item:last-child {
    margin: 0;
  }
}
</style>
