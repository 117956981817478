<template>
  <div class="personality_assessment">
    <navigationBar :showBack="showBack">{{ title }}</navigationBar>
    <personalityItem
      :evaluationList="evaluationList"
      @handelStart="handelStart"
    ></personalityItem>
    <div class="title">热门测评</div>
    <personalityItemHot
      :evaluationHotList="evaluationHotList"
      @handelStart="handelStart"
    ></personalityItemHot>
    <van-popup v-model="showNotice"
      ><notice
        v-if="showNotice"
        :evaluationId="evaluationId"
      ></notice
    ></van-popup>
  </div>
</template>

<script type="text/ecmascript-6">
import navigationBar from "@/components/navigationBar/navigationBar";
import personalityItem from "@/components/personalityItem/personalityItem";
import personalityItemHot from "@/components/personalityItemHot/personalityItemHot";
import notice from "@/components/notice/notice";
import { getEvaluationList, getHotEvaluationList } from "@/request/api";
export default {
  data() {
    return {
      id: 0,
      title: "",
      evaluationList: [],
      evaluationHotList: [],
      showBack: true,
      showNotice: false,
      finished: 0,
      evaluationId: 0,
      loginUser: {}
    };
  },
  components: {
    navigationBar,
    personalityItem,
    personalityItemHot,
    notice,
  },
  created() {
    this.id = this.$route.query.id;
    this.loginUser = JSON.parse(localStorage.getItem("loginUser"));
  },
  mounted() {
    this.getEvaluationList(this.id);
    this.getHotEvaluationList();
  },
  methods: {
    // 测评列表
    getEvaluationList(id) {
      getEvaluationList(id).then((res) => {
        this.getTitle(id);
        this.evaluationList = res.data;
      });
    },

    // 热门推荐
    getHotEvaluationList() {
      getHotEvaluationList().then((res) => {
        this.evaluationHotList = res.data;
      });
    },

    // title
    getTitle(id) {
      if (id == 11000000) {
        this.title = "职业性格测评";
      } else if (id == 12000000) {
        this.title = "创新思维测评";
      } else if (id == 13000000) {
        this.title = "就业倾向测评";
      } else if (id == 14000000) {
        this.title = "创业倾向测评";
      } else if (id == 15000000) {
        this.title = "综合素质测评";
      }
    },

    // 子组件传值
    handelStart(finished, id) {
      if (this.loginUser) {
        this.finished = finished;
        this.evaluationId = id;
        this.showNotice = true;
      } else {
        this.$router.push("/Login");
      }
    },
  },
};
</script>

<style scoped lang="less">
.personality_assessment {
  margin-top: 0.88rem;
  padding: 0 0.2rem 0 0.2rem;
  overflow: hidden;
  .title {
    background-image: url("../assets/images/title_img.png");
    background-repeat: no-repeat;
    background-position: 0.2rem 0.2rem;
    background-size: 1rem 0.12rem;
    font-size: 0.32rem;
    font-family: "PingFangSC-Regular";
    font-weight: 600;
    color: #000000;
    line-height: 0.38rem;
    padding: 0 0.2rem;
    margin: 0.49rem 0 0.4rem 0;
  }
}

/deep/ .van-popup {
  border-radius: 0.12rem;
  background-color: transparent;
}
</style>
